@import "src/style/index";

.auth-input__container{
  width: 100%;
  position: relative;
}
.input-eye__container{
  position: absolute;
  right: 20px;
  top: 18px;
  cursor: pointer;
}


.auth__input {
  width: 100%;
  border-radius: 25px;
  padding: 15px 30px;
  letter-spacing: 0.05em;
  outline: none;
  font-weight: bold;
  font-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &_phone {
    padding: 10px 30px 10px 5px;

  }

  &::placeholder {
    color: $color-gray;
    font-weight: bold;
    font-family: "Montserrat Alternates", sans-serif;
    letter-spacing: 0.05em;
  }
}
.auth__input-normal{
  background: $color-text-white;
  border: 1px solid $color-border-input;
}
.auth__input-warning{
  background: $color-input-warning;
  border: 1px solid $color-google;
}


.validate-warning{
  display: block;
  margin: 10px 0 0 10px;
  font-size: 14px;
  line-height: 17px;
  color: $color-google

}
