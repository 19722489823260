@import "src/style/index";
.round-button{
  width: 40px;
  height: 40px;
  background-color: $color-text-white;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.position__left-bottom{
  position: absolute;
  bottom: 15px;
  left: 20px;
}
.position__right-bottom{
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.position__right-top{
  position: absolute;
  top: 15px;
  right: 15px;
}
.position__left-top{
  position: absolute;
  top: 15px;
  left: 20px;
}

.position__left-bottom{
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.position__right-bottom{
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.position__right-top{
  position: absolute;
  top: 30px;
  right: 30px;
}
.position__left-top{
  position: absolute;
  top: 30px;
  left: 30px;
}


