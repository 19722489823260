@import "src/style/index";
.hint-modal__img{
  width: 26px;
  height: 26px;
}
.hint-modal__text{
  color: $color-text-main;
  margin-left: 25px;
}
.hint-modal__container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 15px 25px 15px ;
  & > div + div {
    margin-top: 22px;
  }
}
.hint-modal__element{
  display: flex;
 align-items: center;

}


.hint-modal{
  position: static;
  color: $color-text-main;
  max-width: 400px;
  border-radius: 15px;
  flex-direction: column;
  padding: 35px 20px 20px 20px;
  z-index: 99;
  border: none;
  background-color: $color-text-white;
  outline: none;
  margin: 0 15px;
  min-width: 320px;
}
.hint-modal__email{
  font-weight: 600;
  display: block;
  margin: 5px 0;
}
.hint-modal__button{
  cursor: pointer;
}
@media only screen and (orientation:portrait){
  .hint-modal__horizontal{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg)
  }
}
@media (min-width: $sm) {
  .hint-modal__horizontal{
    padding: 50px 30px 30px 30px;
  }
  .hint-modal__container{
    margin: 0 20px 35px 20px ;

    & > div + div {
      margin-top: 22px;
    }
  }

}
@media (min-width: $md) {
  .hint-modal__horizontal {
    padding: 50px 30px 30px 30px;

  }
  .hint-modal__container {
    margin: 0 20px 35px 20px;

    & > div + div {
      margin-top: 33px;
    }
  }
}
