@import 'src/style/index';

.error-page {
  flex: 1;
  height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 0;


  &__image {
    flex: 1 1 33%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &-boy {
      //order: 1;
      padding: 10px 0 10px 10px;
    }

    &-girl {
      padding: 10px 10px 10px 0;
      //order: 2;
    }

    & svg {
      max-height: 300px;
      max-width: 250px;
      width: 60%;
      height: 100%;
    }
  }

  &__content {
    padding: 0 5px;
    margin-top: -30px;
    order: 1;
  }

  h3 {
    text-align: center;
  }

  &__children {
    width: 90%;
  }

  &__subtitle {
    margin: 0 0 20px 0;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto;
  }

  & .btn {
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    outline: none;

    &__primary {
      background: $color-text-blue;
      color: white;
    }

    &__secondary {
      background: transparent;
      color: $color-text-blue
    }
  }

  &__modal {
    max-width: 375px !important;
    padding: 20px 15px 15px 15px !important;

    &-point {
      font-weight: 400;
      margin: 0 0 10px 0;

      &.bold {
        margin-top: 20px;
        font-weight: bold;
      }

    }

    &-title {
      margin-top: 0;
    }

    &-button {
      margin-top: 25px;
    }

    &-links {
      display: flex;
      justify-content: center;
      align-items: center;

      & a {
        width: 48px;
        height: 48px;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        //& svg {
        //  width: 36px;
        //  height: 36px;
        //}
      }

      & a + a {
        margin-left: 50px;
      }
    }

    &-viber {
      background-color: #7e57c2;

      & svg {
        width: 36px;
        height: 36px;
      }
    }

    &-telegram {
      background-color: #00bfff;

      & svg {
        width: 32px;
        height: 32px;
        margin-right: 2px;
      }
    }
  }
}

.viber {
  background-color: red;
}


@media screen and (min-width: 768px) {
  .error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    &__image {
      padding: 20px;
      align-items: center;

      & svg {
        max-height: 500px;
        width: 100%;
      }
    }

    &__content {
      width: 100%;
      margin-top: -30px;
      order: 0;
    }

    &__modal {
      max-width: 600px !important;
    }
  }
}

@media screen and (min-width: $lg) {
  .error-page {
    &__image {
      &-boy {
        padding: 20px 0 20px 20px;
        justify-content: flex-end;
      }

      &-girl {
        padding: 20px 20px 20px 0;
        justify-content: flex-start;
      }

      & svg {
        width: 75%;
      }
    }

    &__modal {
      padding: 35px 20px 20px 20px !important;
    }
  }
}

@media screen and (min-width: $xl) {
  .error-page {
    &__content {
      max-width: 600px;
    }
  }
}


