@import "src/style/index";

.lesson {
  background-image: url("../../../assets/image/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  &__animation {
    position: absolute;
    z-index: 1;
    filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;


    &-button {
      width: 100%;
      height: 100%;
      cursor: pointer;

    }
  }

  &__play-button {
    position: relative;
    z-index: 2;
    animation: pulse 2s ease-in-out infinite;

  }

  &__buttonContainer {
    position: absolute;
    height: 100%;
    top: 0;
    right: 30px;
    z-index: 7;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div + div {
      margin-top: 25px
    }
  }
}

.lesson-buttons {
  width: 100%;
  height: 100%;

  & div + div {
    margin-left: 30px;
  }
}

.lesson__circle-button {

  width: 100px;
  height: 100px;
}

.lesson__circle-image {
  width: 60px;
  height: 60px;
}

.lesson-star {
  width: 144px;
  height: 144px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

@media screen and (min-width: $sm), screen and (min-height: $sm) {
  .lesson {
    &__play-button {
      height: 75%;
    }
  }
}

@media screen and (min-width: $sm), screen and (min-height: $sm) {
  .lesson {

    &-buttons {
      & div + div {
        margin-left: 30px;
      }
    }

    &__circle-button {

      width: 120px;
      height: 120px;
    }

    &__circle-image {
      width: 72px;
      height: 72px;
    }

    &__circle-image {
      width: 72px;
      height: 72px;
    }
  }
}

@media screen and (min-width: $md), screen and (min-height: $md) {
  .lesson {
    &-buttons {
      & div + div {
        margin-left: 50px;
      }
    }

    &__circle-button {
      width: 150px;
      height: 150px;
    }

    &__circle-image {
      width: 90px;
      height: 90px;
    }
  }
}

@media screen and (min-width: $lg), screen and (min-height: $lg) {
  .lesson {
    &-buttons {
      & div + div {
        margin-left: 50px;
      }
    }
    &-star {
      width: 250px;
      height: 250px;
      top: 0;
      left: 0;
    }
    &__circle-button {

      width: 300px;
      height: 300px;
    }
    &__circle-image {
      width: 175px;
      height: 175px;
    }
  }
}

@media screen and (min-width: $xl), screen and (min-height: $xl) {
  .lesson {
    &-buttons {
      & div + div {
        margin-left: 70px;
      }
    }
    &-star {
      width: 300px;
      height: 300px;
      top: 0;
      left: 0;
    }
    &__circle-button {

      width: 400px;
      height: 400px;
    }
    &__circle-image {
      width: 250px;
      height: 250px;
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(.85);
  }
  50% {
    transform: scale(1);
  }
  to {
    transform: scale(.85);
  }
}
