@import "src/style/index";

.success-alert__text{
  color: $color-text-main;
  text-align: center;
  margin-bottom: 35px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.05rem;
  line-height: 20px;
};

.success-modal{
  position: static;
  color: $color-text-main;
  max-width: 350px;
  border-radius: 15px;
  flex-direction: column;
  padding: 35px 20px 20px 20px;
  border: none;
  background-color: $color-text-white;
  outline: none;
}

@media only screen and (orientation: portrait) {
  .success-modal{
    transform-origin: top left;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg) translate(-30%, -150%);
  }
}
