@import "src/style/index";

.book-star__container{
  width: 100%;
  height: 100%;
  background: linear-gradient(77.19deg, #67E5F2 0%, #4FFB95 100%);
  position: fixed;
  overflow: hidden;
}

.book-star__element{
  position: relative;
  width: 250px;
  order: 0;
  flex: 0 1 auto;
  align-self: center;
}
.book-star__element-link-container{
  width: 250px;
  position: absolute;
  bottom: 10px;
}
.book-star__element-link{
  cursor: pointer;
}
.book-star__element-link-img{
  width: 17px;
  margin-right: 5px
}
.book-star__element-img{
  border-radius: 9px;
  width: 250px;
}

.book-star__element-text{
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 0.7rem;
  text-decoration: none;
  outline: none;
  color: $color-text-main;
}

.center{
  width: 450px;
}

.slick-slide {
  padding: 0 15px;
  width: 250px;
  margin: 0 15px;
  &:first-child{
    padding-left: 0;
    margin-left: 0;
  }
  &:last-child{
    padding-right: 0;
    margin-right: 0;
  }
}

.slick-list {
  padding: 0 !important;
}

@media screen  and (min-height: $sm), screen and (min-width: $sm){
  .book-star__element{
    width: 300px;
  }
  .book-star__element-img{
    width: 300px;
  }
  .book-star__element-link-container{
    width: 300px;
  }
  .slick-slide{
    width: 300px;
  }
  .center{
    width: 450px;
  }
  .book-star__element-link-img{
    width: 25px;
  }
  .book-star__element-text{
    margin-left: 5px;
    font-size: 0.8rem;
  }
}
@media screen  and (min-height: $md), screen and (min-width: $md){
  .book-star__element{
    width: 350px;
  }
  .book-star__element-img{
    width: 350px;
  }
  .book-star__element-link-container{
    width: 350px;
  }
  .slick-slide{
    width: 350px;
  }
  .center{
    width: 500px;
  }
  .book-star__element-link-img{
    width: 30px;
  }
  .book-star__element-text{
    margin-left: 10px;
    font-size: 0.9rem;
  }
}
@media screen  and (min-height: 800px), screen and (min-width: 800px){
  .book-star__element{
    width: 450px;
  }
  .book-star__element-img{
    width: 450px;
  }
  .book-star__element-link-container{
    width: 450px;
  }
  .slick-slide{
    width: 450px;
  }
  .center{
    width: 600px;
  }
  .book-star__element-link-img{
    width: 30px;
  }
  .book-star__element-text{
    margin-left: 10px;
    font-size: 0.9rem;
  }
}
@media screen  and (min-height: $lg), screen and (min-width: $lg){
  .book-star__element{
    width: 550px;
  }
  .book-star__element-img{
    width: 550px;
  }
  .book-star__element-link-container{
    width: 550px;
  }
  .slick-slide{
    width: 550px;
  }
  .center{
    width: 750px;
  }
  .book-star__element-link-img{
    width: 40px;
  }
  .book-star__element-text{
    margin-left: 15px;
    font-size: 1rem;
  }
  .book-star__element-link-container{
    bottom: 15px;
  }
}
@media screen  and (min-height: $xl), screen and (min-width: $xl){
  .book-star__element{
    width: 750px;
  }
  .book-star__element-img{
    width: 750px;
  }
  .book-star__element-link-container{
    width: 750px;
  }
  .slick-slide{
    width: 750px;
  }
  .center{
    width: 950px;
  }
  .book-star__element-link-img{
    width: 40px;
  }
  .book-star__element-text{
    margin-left: 20px;
    font-size: 1.3rem;
  }
  .book-star__element-link-container{
    bottom: 15px;
  }
  .slick-slide {

    padding: 0 20px;
    margin: 0 20px;
  }
}


