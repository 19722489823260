@import "../../../style/index";
.levels{
  width:100%;
  height: 100%;
  display: grid;
  grid-template-columns: 55px 1fr 55px;
}

.bar{
  display: flex;
  justify-content: center;
  align-items: center;
}

.level-element-available{
  background-color: $color-text-white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.level-element-forbidden{
  background: rgba(255, 255, 255, 0.65);
}

@media screen and (min-width: $sm), screen and (min-height: $sm)  {
  .levels{
    grid-template-columns: 60px 1fr 60px;
  }
}

@media screen and (min-width: $md), screen and (min-height: $md)  {
  .levels{
    grid-template-columns: 100px 1fr 100px;
  }
  .bar-left{
    justify-content: flex-end;
  }
  .bar-right{
    justify-content: flex-start;
  }
}

@media screen and (min-width: 800px), screen and (min-height: 800px)  {
  .levels{
    //grid-template-columns: 100px 1fr 100px;
  }
  }


@media screen and (min-width: $lg), screen and (min-height: $lg)  {
  .levels{
    grid-template-columns: 100px 1fr 100px;
  }
}

@media screen and (min-width: $xl), screen and (min-height: $xl)  {
  .levels{
    grid-template-columns: 120px 1fr 120px;
  }
}
