@import "src/style/index";
.achievements{
  background: linear-gradient(77.19deg, #24D936 0%, #17CF98 100%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.achieve__star-container{
  display: flex;
  flex-direction: column;
  align-items: center;

}
.achieve__star{
  width: 60px;
  height: 60px;
  margin-bottom:25px;
}
.achieve__star-text{
  font-weight: 400;
  color: $color-text-white;
  font-size: 2rem;
}
.achieve__cub-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-text-white;
  margin-left: 80px;
}
.achieve__cub-text{
  font-size: 1.75rem;
}
.achieve__cub-elements{
  display: grid;
  grid-template-columns: repeat(2, 120px);
  grid-template-rows: repeat(2, 70px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.achieve__cub-element{
  display: flex;
  align-items: center;
}
.achieve__cub-img{
  width: 70px;
  height: 70px;
}
.achieve__cub-count{
  margin-left: 10px;
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (min-width: $sm), screen and (min-height: $sm) {
  .achieve__cub-elements{
    grid-template-columns: repeat(2, 140px);
    grid-template-rows: repeat(2, 80px);
  }
  .achieve__cub-img{
    width: 80px;
    height: 80px;
  }
}
@media screen and (min-width: $md), screen and (min-height: $md) {
  .achieve__cub-elements{
    grid-template-columns: repeat(2, 140px);
    grid-template-rows: repeat(2, 100px);
  }
  .achieve__cub-img{
    width: 100px;
    height: 100px;
  }
  .achieve__cub-count{
    margin-left: 20px;
    font-size: 2.5rem;
  }
}
@media screen and (min-width: $lg), screen and (min-height: $lg) {
  .achieve__cub-container{
    margin-left: 150px;
  }
  .achieve__cub-text{
    font-size: 3rem;
  }
  .achieve__cub-elements{
    grid-template-columns: repeat(2, 220px);
    grid-template-rows: repeat(2, 120px);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
  }
  .achieve__cub-img{
    width: 120px;
    height: 120px;
  }
  .achieve__cub-count{
    margin-left: 30px;
    font-size: 3rem;
  }
  .achieve__star{
    width: 100px;
    height: 100px;
    margin-bottom:45px;
  }
  .achieve__star-container{
    margin-top: 100px;
  }
  .achieve__star-text{
    font-size: 2.5rem;
  }
}
