@import "src/style/index";

.login__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__buttons-container {
  width: 100%;
  margin-bottom: 30px;

  & div + div {
    margin-top: 10px;
  }
}

.auth__input-container {
  width: 100%;

  & > div + div {
    margin-top: 20px;
  }
}

.auth__text {
  font-weight: 500;
  font-size: 0.75rem;
  align-self: flex-start;
  margin: 10px 0 0 10px;
}

.auth__text-blue {
  font-weight: bold;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
  color: $color-text-blue;
  cursor: pointer;
}

.auth__text-grey {
  color: $color-gray !important;
}

.auth__text-blue_margin {
  margin-left: 5px;
}

.auth__text-forget {
  margin: 30px 0;
}

.auth-center_wrapper {
  height: 100%;
  max-width: 415px;
  margin: 0 auto;
  padding: 30px 20px 45px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset__input-container {
  width: 100%;
  margin: 50px 0 30px 0;
}

.sign__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign__form-element {
  width: 100%;
  margin-bottom: 20px;
}

.sign__form-label {
  margin: 0 0 10px 10px;
}

.text__sign {
  margin-top: 20px;
}

.auth__redirect-text {
  margin-top: 30px;
}

.auth__link-margin {
  margin-left: 12px;
}

@media (min-width: $lg) {
  .media-height-center {
    height: 100%;
  }
}


