@import "src/style/index";

.overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-dark {
    background-color: rgba(0, 0, 0, 0.85);
  }
  &-light {
    background-color: rgba(0, 0, 0, 0.35);
  }
}


.loading-modal{
  position: static;
  color: lightblue;
  margin: 20px;
  background-color: transparent;
  outline: none;
  z-index: 100;
}
.loader-icon{
  width: 200px;
  height: 200px;
}
@media screen and (min-width: $sm), screen and (min-height: $sm){
  .loading-modal{
    min-width: 300px;
    min-height: 300px;
  }
  .loader-icon{
    width: 300px;
    height: 300px;
  }
}@media screen and (min-width: $lg), screen and (min-height: $lg){
  .loading-modal{
    min-width: 500px;
    min-height: 500px;
  }
  .loader-icon{
    width: 500px;
    height: 500px;
  }
}
