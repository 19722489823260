@import 'src/style/index';
.login__button-google {
  width: 100%;
  background-color: $color-google;
  box-shadow: 0 4px 20px rgba(219, 68, 55, 0.55);
}

.login__buttons-facebook{
  width: 100%;
  background: $color-facebook;
  box-shadow: 0 4px 20px rgba(59, 89, 152, 0.55);
}

.login__buttons_icon-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__button-blue{
  width: 100%;
  background: $color-text-blue;
  box-shadow: 0px 4px 20px rgba(0, 166, 255, 0.55);

  &-outlined {
    width: 100%;
    background: transparent;
    border: 1px solid $color-text-blue !important;
    color: $color-text-blue !important;

  }
}
.login__buttons{
  border: none;
  outline: none;
  cursor: pointer;
  height: 50px;
  border-radius: 25px;
  color: $color-text-white;
  font-weight: bold;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.05rem;
}
