@import "src/style/index";
.video-container {
  float: left;
  top: 0;
  right: 0;
  padding: 0;
  position: fixed;
  min-height:  100%;
  min-width: 100%;
  width: 110%;
  height: 100%;
  overflow: hidden;
}
.video-tag{
  width: calc(var(--vh)*177.77777778);
  min-width: 100%;
  min-height: calc(var(--vw)*56.25) ;
}

@media screen and (orientation:portrait) {
  .video-container {
    min-width:  100%;
    min-height: 100%;
    z-index: -1;
  }
  .video-tag{
    height: 100%;
    width: calc(var(--vw)*177.77777778);
    min-height: 100%;
    min-width: calc(var(--vh)*56.25) ;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1.35);
  }
}
@media screen and (orientation:portrait) and (min-height: $md) {
  .video-container {
    min-width:  100%;
    min-height: 100%;
    z-index: -1;
  }
  .video-tag{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(1.35);
    width: 100%;
    height: 100%;
  }
}




