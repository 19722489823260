// COLORS
$color-google: #db4437;
$color-input-warning: #FFF1F0;
$color-facebook: #3B5998;
$color-text-main: #212121;
$color-border-input: #CBCCD0;
$color-text-blue: #00a6ff;
$color-text-white: #ffffff;
$color-gray: #C2BCC4;
$color-text-green: #3EDC6A;
$color-pink: #F0519D;
$color-coral: #FF9671;
$color-line: #E8E8E8;
$color-white: #ffffff;
$color-yellow: #F9C710;
// !COLORS

// window horizontal width
$esm: 576px;
$sm: 650px;
$md: 700px;
$lg: 992px;
$xl: 1200px;
$xxl: 1250px;
// !window horizontal width

// window portrait width
$psm: 355px;
$pmd: 410px;
// !window portrait width

