@import "src/style/index";

.home {
  z-index: 1;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.home__nav {
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 18px;
  }
}

.nav-element {
  cursor: pointer;
  border-radius: 15px;
  width: 65px;
  height: 65px;
}

.nav-element__lock {
  position: absolute;
}

.nav-element_white-background {
  background-color: $color-text-white;
}

.nav-element_gray-background {
  background-color: $color-gray;
}

.nav-element__img {
  width: 50px;
  height: 50px;
}

.home__profile {
  overflow: hidden;
  cursor: pointer;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid $color-text-white;
  margin-left: 25px;
}

.home__profile-span {
  font-size: 3rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: $color-text-white;
}

.home__verification {
  cursor: pointer;
  padding: 8px 14px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: $color-text-white;
  border-radius: 14px;
}

.home__verification-text {
  margin-left: 10px;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.child__image {

  width: 100%;
  height: 100%;
  transform: scale(1.4);
}

.login__image {
  margin-right: 5px;
  width: 50px;
  height: 41px;
}

@media screen and (min-width: $sm), screen and (min-height: $sm) {
  .nav-element {
    width: 100px;
    height: 100px;
  }
  .nav-element__img {
    width: 80px;
    height: 80px;
  }
  .home__profile {

    border: 4px solid $color-text-white;
    margin-left: 25px;
    width: 120px;
    height: 120px;
  }
  .home__profile-span {
    font-size: 3.5rem;
  }
  .login__image {
    margin-right: 10px;
    width: 66px;
    height: 56px;
  }
}

@media screen and (min-width: $md), screen and (min-height: $md) {
  .nav-element {
    width: 120px;
    height: 120px;
  }
  .nav-element__img {
    width: 80px;
    height: 80px;
  }
  .home__profile {

    border: 4px solid $color-text-white;
    margin-left: 30px;
    width: 132px;
    height: 132px;
  }
  .home__profile-span {
    font-size: 4rem;
  }
  .login__image {
    margin-right: 10px;
    width: 76px;
    height: 66px;
  }
}

@media screen and (min-width: $lg), screen and (min-height: $lg) {

  .home__nav {
    & > div + div {
      margin-left: 30px;
    }
  }
  .nav-element {
    width: 150px;
    height: 150px;
  }
  .nav-element__img {
    width: 120px;
    height: 120px;
  }
  .home__profile {

    border: 4px solid $color-text-white;
    margin-left: 40px;
    width: 180px;
    height: 180px;
  }
  .home__profile-span {
    font-size: 7rem;
  }
  .login__image {
    margin-right: 10px;
    width: 106px;
    height: 90px;
  }
}

@media screen and (min-width: $xl), screen and (min-height: $xl) {
  .nav-element {
    width: 192px;
    height: 192px;
  }
  .nav-element__img {
    width: 140px;
    height: 140px;
  }
  .home__profile {

    margin-left: 40px;
    width: 200px;
    height: 200px;
  }
  .home__profile-span {
    font-size: 7rem;
  }
  .login__image {
    margin-right: 10px;
    width: 126px;
    height: 100px;
  }
}
