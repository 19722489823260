.game{
  &__buttonContainer{
    position: absolute;
    height: 100%;
    top: 0;
    right: 30px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > div + div, & > a + a {
      margin-top: 25px
    }
  }

  &__star{
    &Container{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 7;
    }

    &-showAnimated{
      animation: showGameStar 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }
    &-hideAnimated{
      animation:  hideGameStar 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }

  }

  &__button{

    &-showAnimatedBottom{
      animation: showButtonBottom 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }
    &-showAnimatedTop{
      animation: showButtonTop 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }
    &-hideAnimatedBottom{
      animation:  hideButtonBottom 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }
    &-hideAnimatedTop{
      animation:  hideButtonTop 0.5s 1 cubic-bezier(1, 1, 0, 0);
    }
  }
}
