@import "src/style/index";

.back-button-container{
  padding: 25px;
}
.back-button{
  display: flex;
  justify-content: center;
  align-items: center;
  //width: 50px;
  //height: 50px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (min-width: $sm) {
  .back-button-container{
    padding: 30px 40px;
  }
}
