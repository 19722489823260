@import "src/style/index";
.log-out__confirm{
  color: $color-text-blue;
  cursor: pointer;
}
.log-out-button {
  width: 110px !important;
  height: 46px;
  border-radius: 23px;
  cursor: pointer;
}

.log-out__refusal{
  background: $color-text-green;
  box-shadow: 0 4px 20px rgba(62, 220, 106, 0.55);
  color: $color-text-white;
}
.log-out__button-container{
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.05em;
  font-weight: 700;
  align-items: center;

}
.log-out-text{
  font-weight: 400;
  letter-spacing: 0.05rem;
  cursor: pointer;
}
