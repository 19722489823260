@import 'src/style/index';
@import 'src/pages/auth/auth';

* {
  box-sizing: border-box;
}

html, body {
  font-size: 16px;
  margin: 0;
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: bold;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: manipulation;
  letter-spacing: 0.05rem;
}

#root {
  //width: 100%;
  //height: 100%;
}

.main__wrapper {
  max-width: 420px;
  margin: 0 auto;
  padding: 30px 20px 45px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.reset__text {
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.05rem;
  line-height: 20px;
  margin-bottom: 40px;
}

.margin-bottom_20 {
  margin-bottom: 20px !important;
}

.margin-bottom_25 {
  margin-bottom: 25px !important;
}

.margin-bottom_30 {
  margin-bottom: 30px !important;
}

.margin-bottom_50 {
  margin-bottom: 50px;
}

.margin-bottom_10 {
  margin-bottom: 10px;
}

.margin-bottom_15 {
  margin-bottom: 15px;
}

.margin-bottom_40 {
  margin-bottom: 40px;
}

.margin-bottom_0 {
  margin-bottom: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-left_10 {
  margin-left: 10px;
}

.margin-top_50 {
  margin-top: 50px
}

.margin-top_30 {
  margin-top: 30px
}

.padding-top_100 {
  padding-top: 100px;
}

.height-all-screen {
  height: 100%;
}

.all-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.green-arrow-image {
  margin-left: 10px;
}

.opacity {
  opacity: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-blue {
  color: $color-text-blue;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  white-space: nowrap;
}

.text__red {
  color: $color-google;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
}

.app {
  //width: 100%;
  //height: 100%;
  position: relative;
}

.auth__title {
  font-weight: bold;
  font-size: 1.25rem;
  letter-spacing: 0.05rem;
}

.auth_subtitle {
  margin-bottom: 30px;
}

.login__button-img {
  margin-right: 20px;
}

.login__button-green {
  width: 100%;
  background-color: $color-text-green;
  box-shadow: 0 4px 20px rgba(62, 220, 106, 0.55);
}

.login__button-green-light {
  color: #38D560;
  font-size: 16px;
  font-weight: 700;
  background: rgba(56, 213, 96, 0.10);

}

.login__button-blue {
  width: 100%;
  color: white;
  background-color: $color-text-blue;
  box-shadow: 0 4px 20px rgba(1, 146, 220, 0.55);
}

.login__button-gray {
  width: 100%;
  background-color: $color-gray;
  cursor: default;
}

.small-text {
  line-height: 15px;
  color: $color-text-main;
  font-size: 0.75rem;
  font-weight: 500;
}

.bgc_coral {
  background-color: $color-coral;
}

.bgc_blue {
  background-color: $color-text-blue;
}

.transform-arrow {
  transform: rotate(0.25turn);
}

.blue-arrow-image {
  margin-bottom: 4px;
}

.screen-shadow {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 50;

  &__loader {
    position: static;
  }
}

.without__last-border:last-child {
  border-bottom: none !important
}

.grid-list {
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(5, 55px);
  grid-template-rows: repeat(2, 78px);
  grid-column-gap: 15px;
  grid-row-gap: 15px
}

.grid-element {
  flex-direction: column;
  border-radius: 20px;
  cursor: pointer;
}

.grid-element-image {
  width: 30px;
  height: 30px;
}

.grid-element-text {
  margin-top: 10px;
  font-size: 0.7rem;
}

.navigation__black-shadow {
  &:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
}

.navigation__red-shadow {
  &:hover {
    box-shadow: 0 4px 20px rgba(219, 68, 55, 0.55);
  }
}

.lock-element-animate {
  animation: lock 1.5s 1;
}
.lesson-game__star-boy_animation{
  animation: 1.5s ease-in 0.5s both lock !important;
}
.lesson__button-homeAnimation {
  animation: 3s ease-in 4s 1 both bigScaleLeft, showButtonTop 0.5s 1 cubic-bezier(1, 1, 0, 0) !important;
}
.lesson__button-youTubeAnimation {
  animation:  3s ease-in 13s 1 both bigScaleLeft, showButtonTop 0.5s 1 cubic-bezier(1, 1, 0, 0) !important;
}
.lesson__button-cubesAnimation {
  animation:  3s ease-in 21s 1 both bigScaleLeft, showButtonBottom 0.5s 1 cubic-bezier(1, 1, 0, 0) !important;
}
.lesson__main-screen_video-animation {
  animation: smallScaleCenter 3s linear 8s
}
.lesson__main-screen_ball-animation {
  animation: smallScaleCenter 3s linear 16s
}
.lesson__main-screen_girl {
  animation: lock 3s linear 1s, lock 3s linear 30s
}
.lesson__main-screen-home {
  animation: topRightButtonScale 3s linear 27s
}
.lesson__main-screen-cub {
  animation: bottomRightButtonScale 3s linear 20s
}
.game__button-homeAnimation {
  animation: 3s ease-in 8s 1 both bigScaleLeft, showButtonBottom 0.5s 1 cubic-bezier(1, 1, 0, 0) !important;
}
.game__button-ballAnimation {
  animation: 3s ease-in 17s 1 both bigScaleLeft, showButtonBottom 0.5s 1 cubic-bezier(1, 1, 0, 0) !important;
}

@keyframes lock {
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes newStar {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  25% {
    opacity: 0.4;
    transform: rotate(90deg) scale(0.7);
  }
  50% {
    opacity: 1;
    transform: rotate(180deg) scale(1.4);
  }
  75% {
    transform: rotate(270deg) scale(2.1);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showButtonBottom {
  0% {
    opacity: 0;
    transform: translateY(300px);
  }
  50% {
    opacity: 0.7;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes showButtonTop {
  0% {
    opacity: 0;
    transform: translateY(-300px);
  }
  50% {
    opacity: 0.7;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes hideButtonTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.7;
    transform: translateY(-50px);
  }
  100% {
    opacity: 0;
    transform: translateY(-300px);
  }
}

@keyframes hideButtonBottom {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.7;
    transform: translateY(50px);
  }
  100% {
    opacity: 0;
    transform: translateY(300px);
  }
}

@keyframes showGameStar {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  50% {
    opacity: 0.7;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes hideGameStar {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  50% {
    opacity: 0.7;
    transform: translateX(-50px);
  }
  100% {
    opacity: 0;
    transform: translateX(-200px);

  }
}

@keyframes lessonCub {
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.6);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1.0);
  }
}
@keyframes bigScaleLeft {
  25% {
    transform: translateX(-57vw) scale(1.3) rotate(-0.05turn)
  }
  30% {
    transform: translateX(-49vw) scale(1.3)
  }
  50% {
    transform: translateX(-49vw) scale(2.5)
  }
  70% {
    transform: translateX(-49vw) scale(1.3)
  }
  95% {
    transform: scale(1.05) rotate(0.05turn)
  }
  100% {
    transform: scale(1.0);
  }
}
@keyframes smallScaleCenter {
  50% {
    transform: scale(1.3)
  }
  100% {
    transform: scale(1)
  }
}
@keyframes bottomRightButtonScale {
  25% {
    transform: translate(-50px, -50px) scale(1.3)
  }
  50% {
    transform: translate(-75px, -75px) scale(1.5)
  }
  70% {
    transform: translate(-50px, -50px) scale(1.3)
  }
  100% {
    transform: translate(0, 0) scale(1.0)
  }
}

@keyframes topRightButtonScale {
  25% {
    transform: translate(-50px, 50px) scale(1.3)
  }
  50% {
    transform: translate(-75px, 75px) scale(1.5)
  }
  70% {
    transform: translate(-50px, 50px) scale(1.3)
  }
  100% {
    transform: translate(0, 0) scale(1.0)
  }
}

@media screen and (min-width: $sm), screen and (min-height: $sm) {

  .grid-list {
    grid-template-columns: repeat(5, 85px);
    grid-template-rows: repeat(2, 112px);
    grid-column-gap: 20px;
    grid-row-gap: 20px
  }
  .grid-element-image {
    width: 50px;
    height: 50px;
  }
}

@media screen and (min-width: $md), screen and (min-height: $md) {

  .grid-list {
    grid-template-columns: repeat(5, 95px);
    grid-template-rows: repeat(2, 125px);
    grid-column-gap: 30px;
    grid-row-gap: 30px
  }
  .grid-element-image {
    width: 60px;
    height: 60px;
  }
  .grid-element-text {
    margin-top: 15px;
    font-size: 1rem;
  }
}

@media screen and (min-width: $lg), screen and (min-height: $lg) {
  .levels {
    grid-template-columns: 80px 1fr 80px;
  }

  .grid-list {
    grid-template-columns: repeat(5, 130px);
    grid-template-rows: repeat(2, 171px);
    grid-column-gap: 35px;
    grid-row-gap: 35px
  }
  .grid-element-image {
    width: 80px;
    height: 80px;
  }
  .grid-element-text {
    margin-top: 20px;
    font-size: 1.2rem;
  }
  .green-arrow-image {
    margin-left: 15px;
  }
}

@media screen and (min-width: $xl), screen and (min-height: $xl) {
  .levels {
    grid-template-columns: 100px 1fr 100px;
  }
  .grid-list {
    grid-template-columns: repeat(5, 156px);
    grid-template-rows: repeat(2, 206px);
    grid-column-gap: 35px;
    grid-row-gap: 35px
  }
  .grid-element-image {
    width: 100px;
    height: 100px;
  }
  .grid-element-text {
    margin-top: 25px;
    font-size: 1.3rem;
  }
}


@media only screen and (orientation: portrait) {
  .vertical-screen {
    overflow: hidden;
    height: calc(var(--vw) * 100);
    width: calc(var(--vh) * 100);
    transform-origin: top left;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg) translate(0, -100%);
  }
}

@media only screen and (orientation: landscape) {
  .vertical-screen {
    overflow: hidden;
    height: calc(var(--vh) * 100);
    width: calc(var(--vw) * 100);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}



