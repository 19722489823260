@import "src/style/index";

.cornerButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  background-color: $color-white;
  border-bottom-left-radius: 50px;
  width: 50px;
  height: 50px;

  &.active {
    background-color: rgba(255, 255, 255, 0.35);
  }

  //&-animation {
  //  animation:  3s ease-in 39s 1 both cornerAnimation
  //}

  &__arrow {
    position: absolute;
    top: 25%;
    right: 25%;
    width: 16px;
    height: 16px;
    border: 3px solid $color-text-blue;
    border-top: none;
    border-right: none;
    cursor: pointer;
    border-bottom-left-radius: 2px;
    transition: 0.5s;

    &.active {
      border: 3px solid $color-text-white;
      transform: rotate(180deg);
      border-right: none;
      z-index: 6;
      border-top: none;
    }
  }
}

//@keyframes cornerAnimation {
//  25% {
//    transform: scale(1.7) translate(-10px, 10px);
//
//  }
//  50% {
//    transform: scale(2.4) translate(-15px, 15px);
//  }
//  75% {
//    transform: scale(1.8) translate(-5px, 5px);
//  }
//  100% {
//    transform: scale(1.0);
//  }
//}
