@import "../../../style/index";
@import "./components/Lessons/gridVariables";

.lessons {
  display: grid;
  grid-template-columns: 1fr 100px;
  width: 100%;
  height: 100%;

  &-grid {
    overflow: hidden;
    align-self: center;
    justify-self: center;
    display: grid;
    grid-template-columns: repeat(5, $lessonHeightXs + px);
    grid-column-gap: $lessonGapXs + px;
    grid-row-gap: $lessonGapXs + px;
    max-height: 185px;
    position: relative;

    &::-webkit-scrollbar-track {
      border-radius: 0.125rem;
      background-color: $color-white;
    }
    &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 0.125rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.125rem;
      background-color: $color-line;
    };
  }

  &__elements {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-style {
    background: rgba(255, 255, 255, 0.33);
    border-radius: 20px;
    width: 100%;
    height: 85px;
  }

  &-nav {
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_circle {
      width: 40px;
      height: 40px;
    }

    &_circle-arrow {
      margin-right: 5px;
      margin-top: 1px;
    }

    &-image {
      width: 25px;
      height: 25px;
    }

    &_progress {
      color: $color-text-white;
      display: flex;
      align-items: center;

      &-image {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  &-button_margin {
    margin-bottom: 20px;
  }

  &__nav {
    &-button {
      height: 25px;
      width: 385px;

      &.bottom {
        margin-top: 5px;
      }

      &.top {
        margin-bottom: 5px;
      }
    }
    &-arrow {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(255,255,255,0.2);
      cursor: pointer;

      &.top {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &.bottom {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        & img {
          transform: rotate(180deg);
        }
      }
    }
  }
}


@media screen and (min-width: $sm), screen and (min-height: $sm) {

  .lessons-grid {
    grid-template-columns: repeat(5, $lessonHeightSm + px);
    grid-column-gap: $lessonGapSm + px;
    grid-row-gap: $lessonGapSm + px;
    max-height: 240px;
  }
  .lessons-style {
    background: rgba(255, 255, 255, 0.33);
    border-radius: 20px;
    height: 112px;
  }

  .lessons-nav_circle {
    width: 50px;
    height: 50px;
  }
  .lessons-button_margin {
    margin-bottom: 30px;
  }
  .lessons__nav-button {
    width: 485px;
  }
}

@media screen and (min-width: $md), screen and (min-height: $md) {
  .lessons {
    &-grid {
      grid-template-columns: repeat(5, 100px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      max-height: 285px;
    }

    &-style {
      width: 100%;
      height: 132px;
    }
    &__nav-button {
      width: 585px;
    }
  }
}

@media screen and (min-width: 800px), screen and (min-height: 800px) {
  .lessons {
    grid-template-columns: 1fr 150px;

    &-grid {
      padding-left: 30px;
      grid-template-columns: repeat(5, 100px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      max-height: 285px;
    }

    &-style {
      width: 100%;
      height: 132px;
    }
    &__nav-button {
      padding-left: 30px;
      width: 614px;
    }
  }
}

@media screen and (min-width: $lg), screen and (min-height: $lg) {
  .lessons {
    grid-template-columns: 1fr 190px;

    &-grid {
      grid-template-columns: repeat(5, 130px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      max-height: 555px;
    }

    &-style {
      width: 100%;
      height: 171px;
    }
    &__nav-button {
      width: 764px;
    }
  }
  .lessons-nav_circle {
    width: 65px;
    height: 65px;
  }
  .lessons-button_margin {
    margin-bottom: 50px;
  }
  .lessons-nav-image {
    width: 40px;
    height: 40px;
  }
  .lessons-nav_cub-image {
    width: 120px;
    height: 120px;
  }
  .lessons-nav_circle-house {
    margin-bottom: 4px;
    margin-left: 2px;
  }
  .lessons-nav_progress-image {
    width: 36px;
    height: 36px;
  }
  .lessons-nav_progress-num {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: $xl), screen and (min-height: $xl) {
  .lessons {
    grid-template-columns: 1fr 250px;

    &-grid {
      grid-template-columns: repeat(5, 156px);
      grid-column-gap: 35px;
      grid-row-gap: 35px;
      max-height: 690px;
    }

    &-style {
      width: 100%;
      height: 206px;
    }
    &-nav{
      &-image {
        width: 50px;
        height: 50px;
      }
      &_circle {
        width: 80px;
        height: 80px;
      }
      &_progress-image {
        width: 48px;
        height: 48px;
      }
      &_progress-num {
        font-size: 1.7rem;
      }
    }

    &-button_margin {
      margin-bottom: 80px;
    }
    &__nav-button {
      width: 954px;
    }
  }
}

.starAnimation {
  animation: newStar 2s 1 cubic-bezier(1, 1, 0, 0);
}

.cub-animation {
  animation: lessonCub 2s 1 cubic-bezier(1, 1, 0, 0);
}


