.portrait__container{
  position: absolute;
  z-index: 5;
  height:  100%;
  width: 100%;
  background-size: cover;
  opacity: 0.2;
  background-color: red;
}
.portrait-img{
  width: 300px;
  transform: rotate(-45deg);
  animation: phone-rotate 1.5s infinite;
}
.game-container{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes phone-rotate {
  25% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(-135deg);
  }
}
