.gameMenu{
  position:absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0.35;
  z-index: 5;

  &__imgContainer{
    position: absolute;

  }
}
