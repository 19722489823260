@import "src/style/index";

.qr-modal {
  padding: 50px 82px 70px 82px;
  position: absolute;
  color: $color-text-main;
  max-width: 1000px;
  width: 100%;
  border-radius: 15px;
  flex-direction: column;
  z-index: 99;
  border: none;
  background-color: $color-text-white;
  outline: none;
  margin: 0 15px;
  min-width: 320px;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &__title {
    font-family: Montserrat Alternates;
    font-size: 24px;
    font-weight: 600;
    line-height: 140%;
    max-width: 600px;
    text-align: center;
    margin: 0 auto 50px auto;
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__line {
    height: 450px;
    width: 1px;
    background: #C5C5C5;
    margin: 0 115px;
  }

  &__box {
    text-align: center;
    padding-bottom: 50px;

    & p {
      color: #2F2F2F;
      text-align: center;
      font-family: Montserrat Alternates;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      margin-top: 0;
    }

     & img {
       max-width: 300px;
       width: 300px;
       height: 300px;
     }
  }
}

@media screen and (max-width: $lg) {
  .qr-modal {
    overflow: scroll;
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__line {
      display: none;
    }
  }
}

@media screen and (max-width: $lg) {
  .qr-modal {
    margin: 0 25px;
    padding: 70px 20px 30px 20px;
    &__container {
      display: flex;
      flex-direction: column;
    }
    &__line {
      display: none;
    }
    &__box {
      p {
        margin-bottom: 10px;
      }
      & img {
        width: 200px;
        height: 200px;
      }
    }
  }
}
